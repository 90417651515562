import classes from './GrowthProgramMain.module.scss';
import ClientList from './components/ClientList';
import RenderCardsList from './components/RenderCardsList';
import ResearchCard from './components/ResearchCard';
import { StickerColor } from 'components/shared/ColorSticker';
import EventCard from './components/EventCard';
import { ColorTagProps } from 'components/shared/ColorTag';
import expert_event_img from 'assets/img/GrowthProgram/expert-event.png';
import buisness_dinner_img from 'assets/img/GrowthProgram/buisness_dinner_optimized.png';
import webinar_img from 'assets/img/GrowthProgram/webinar_optimized.png';
import _ from 'lodash';
import SolutionsStand from './components/SolutionsStand';
import { useUserStore } from 'contexts/UserContext';
import CircleIcon from 'components/shared/CircleIcon';
import { FileIcon } from 'components/shared/Icons/FileIcon';
import { Link } from 'components/shared/Link';
import { useAnchorScroll } from 'hooks/useAnchorScroll';
import React, { useEffect } from 'react';

interface MockResearch {
  section: string;
  title: string;
  text: string;
  tagColor: StickerColor;
  fileSize: number;
  fileLink: string;
  fileDate: string;
}

interface MockEvent {
  slug: string;
  img: string;
  title: string;
  description: string;
  tags?: Omit<ColorTagProps, 'className'>[];
  date: string;
  location?: string;
  link?: string;
  downloadFile?: string;
}

export default function GrowthProgramMainContainer() {
  const userStore = useUserStore();
  const client = userStore?.client || undefined;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useAnchorScroll();

  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>
        <span>{'Программа роста'}</span>
        <Link href="/programma-rosta/rules">
          <CircleIcon className={classes.title__icon} icon={<FileIcon />} />
        </Link>
      </div>

      <div className={classes.content}>
        <ClientList
          client={client}
          bonusBalance={userStore.user?.available_bonus_balance || 0}
        />

        <RenderCardsList
          id={'research'}
          list={[
            {
              section: 'Fashion',
              title:
                'Коды премиальности на Wildberries и Ozon в категории одежды',
              text: 'Документ исследует вопрос, как бренды одежды, растущие в продажах на Wildberries и OZON, формируют образ премиального, дорогого продукта. В документе проанализировано три ключевых элемента: продукт, бренд и коммуникация — для каждого из аспектов выделяются типичные и нетипичные подходы, подкрепленные примерами. Завершается обзор выводами и рекомендациями.',
              tagColor: 'purple',
              fileDate: '2024-11-20T13:26:29.987Z',
              fileLink:
                '/files/GrowthProgram/Коды премиальности на Wildberries и Ozon в категории одежды.pdf',
              fileSize: 10074112,
            },
            {
              section: 'Fashion',
              title: ' Reels-стратегия на трендах (лето 2024)',
              text: 'Анализ контентных трендов в актуальных динамических видеоформатах fashion-брендов летом 2024 года. Описание способов использования этих трендов для создания контентной стратегии бренда. В документе подробно описываются актуальные форматы, темы, примеры и советы по использованию каждого тренда, с разбивкой по степени актуальности: always in trend, established, emerged, declining.',
              tagColor: 'purple',
              fileDate: '2024-11-20T13:26:29.987Z',
              fileLink: '/files/GrowthProgram/Reels-стратегия на трендах.pdf',
              fileSize: 4360192,
            },
            {
              section: 'Фармацевтика',
              title: 'Онлайн-аптеки в России в 2024 г., обзор-прогноз',
              text: 'Аналитический отчет, посвященный рынку онлайн-аптек в России в 2024 году и трендам, которые будут актуальны ближайшей перспективе. В документе рассматриваются ключевые тенденции развития рынка, статистика продаж, характеристики спроса и особенности поведения покупателей, даются рекомендации по подходам к коммуникации и маркетингу.',
              tagColor: 'green',
              fileDate: '2024-11-20T13:26:29.987Z',
              fileLink:
                '/files/GrowthProgram/Онлайн-аптеки в России в 2024 г., обзор-прогноз.pdf',
              fileSize: 665600,
            },
          ]}
          title="Исследования"
          render={(item, index) => {
            const tempItem = item as MockResearch;
            return (
              <ResearchCard
                key={_.uniqueId()}
                currentIndex={index}
                section={tempItem.section}
                title={tempItem.title}
                description={tempItem.text}
                tagColor={tempItem.tagColor}
                fileDate={tempItem.fileDate}
                fileLink={tempItem.fileLink}
                fileSize={tempItem.fileSize}
              />
            );
          }}
        />

        <RenderCardsList
          id={'event'}
          list={[
            {
              slug: 'ekspertnost-kak-podcherknut-svoi-supersily',
              img: expert_event_img,
              title: 'Экспертность — как подчеркнуть свои «суперсилы»?',
              description:
                'На вебинаре обсудим создание личного бренда с СЕО Pi-Shaped',
              tags: [
                { color: 'green', text: 'Онлайн' },
                { color: 'purple', text: 'Личный бренд' },
              ],
              date: '19.02.2025 в 12:00',
            },
            {
              slug: 'sekrety-premium-kak-prodavatsya-dorozhe',
              img: webinar_img,
              title: 'Секреты premium - как продаваться дороже?',
              description:
                'Роль стратегии бренда и «эффекта premium» в бизнес-результатах компании',
              tags: [
                { color: 'green', text: 'Онлайн' },
                { color: 'purple', text: 'Средний бизнес' },
                {
                  color: 'yellow',
                  text: 'Растущий бизнес',
                },
              ],
              date: '03.03.2025 в 10:30',
            },
            {
              slug: 'inogda-oni-vozvrashchayutsya-a-chto-esli',
              img: buisness_dinner_img,
              title: 'Иногда они возвращаются: а что, если?..',
              description:
                'Обсудим основные принципы, которые могут быть полезны компаниям и брендам, в категории которых могут вернуться глобальные игроки',
              tags: [
                { color: 'green', text: 'Онлайн' },
                { color: 'yellow', text: 'Мультикатегорийная' },
              ],
              date: 'Завершено 07.02.2025',
              downloadFile: '/files/GrowthProgram/Иногда они возвращаются.pdf',
            },
          ]}
          title="Мероприятия"
          render={(item) => {
            const tempItem = item as MockEvent;
            return (
              <EventCard
                key={_.uniqueId()}
                imgSrc={tempItem.img}
                title={tempItem.title}
                description={tempItem.description}
                tags={tempItem.tags}
                eventDate={tempItem.date}
                eventLocation={tempItem.location}
                eventLink={tempItem.link}
                buttonName="Подробнее"
                slug={tempItem.slug}
                downloadFile={tempItem.downloadFile}
              />
            );
          }}
        />

        <SolutionsStand title="Витрина списания баллов" />
      </div>
    </div>
  );
}
