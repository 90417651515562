import clsx from 'clsx';
import { DownloadIcon } from 'components/shared/Icons/DownloadIcon';
import { NavLink } from 'react-router-dom';
import classes from '../Table.module.scss';
import { MainContentProps } from './MainContent.props';

const MainContent = ({ value, handleDownload }: MainContentProps) => {
  if (value?.filePath) {
    return (
      <span
        className={clsx(
          classes.table__link,
          value?.className?.length && String(value?.className)
        )}
        onClick={() =>
          value?.filePath &&
          handleDownload(value?.filePath, value?.value as string)
        }
      >
        {value?.value}

        {value?.filePath && (
          <DownloadIcon
            onClick={() =>
              handleDownload(value?.filePath as string, value?.value as string)
            }
          />
        )}
      </span>
    );
  } else if (value?.link && value?.linkOptions) {
    return (
      <NavLink
        to={process.env.REACT_APP_PUBLIC_URL + value?.link}
        className={clsx(
          classes.table__firstlink,
          classes.table__link,
          value?.className?.length && String(value?.className)
        )}
      >
        {value?.value}
      </NavLink>
    );
  } else {
    return <>{value?.value}</>;
  }
};

export default MainContent;
