import desktop from 'assets/img/Login/desktop.png';
import es_desktop from 'assets/img/Login/es_desktop.png';
import logo from 'assets/img/logo.png';
import logo_ru from 'assets/img/logo_ru.svg';
import es_mobile from 'assets/img/Registration/mobile-solutions-es.png';
import mobile from 'assets/img/Registration/mobile-solutions.png';
import clsx from 'clsx';
import { Button } from 'components/shared/Button';
import { EyeIcon } from 'components/shared/Icons/EyeIcon';
import { InfoIcon } from 'components/shared/Icons/InfoIcon';
import { Input } from 'components/shared/Input';
import { Link } from 'components/shared/Link';
import { MultiselectComponent } from 'components/shared/MultiselectComponent';
import { TitleAndDescription } from 'components/shared/TitleAndDescription/TitleAndDescription';
import { TooltipContainer } from 'components/shared/Tooltip/TooltipContainer';
import { languages } from 'constants/languages';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Controller, ControllerRenderProps } from 'react-hook-form';
import { NavLink, useLocation } from 'react-router-dom';
import { RUSSIAN_LOCALE } from 'utils';
import classes from './Login.module.scss';
import { FormInputs, useLogin } from './useLogin';

export const LoginContainer = observer(() => {
  const {
    t,
    i18n,
    changeLanguage,
    defaultLanguage,
    errors,
    onSubmit,
    handleSubmit,
    isDirty,
    isValid,
    control,
    isDisableCaptcha,
    invite,
  } = useLogin();
  const { isMediaTablet } = useWindowWidth();
  const [showPassword, setShowPassword] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const source = new URLSearchParams(location.search)?.get('utm_source');
    const existSource = localStorage.getItem('utm_mark');

    if (source && !existSource) {
      localStorage.setItem('utm_mark', source);
    }
  }, []);

  return (
    <>
      <TitleAndDescription pageName="Login" />

      <div className={classes.container}>
        <NavLink
          to={RUSSIAN_LOCALE ? '#' : `${process.env.REACT_APP_MAIN_SITE_URL}`}
        >
          <img
            src={RUSSIAN_LOCALE ? logo_ru : logo}
            alt=""
            className={clsx(classes.logo, RUSSIAN_LOCALE && classes.logo_ru)}
          />
        </NavLink>

        <div className={classes.left}>
          <div className={classes['left-title']}>
            {t('KIT Global — world-class digital solutions')}
          </div>
          <div className={classes['left-text']}>
            {t(
              'We know how to improve your business through internet marketing with no limits'
            )}
          </div>
          <img
            src={
              isMediaTablet
                ? i18n.language === 'es'
                  ? es_mobile
                  : mobile
                : i18n.language === 'es'
                  ? es_desktop
                  : desktop
            }
            alt=""
            className={classes.image}
          />
        </div>

        <div className={classes.right}>
          {invite && (
            <div className={classes.invitation}>
              {t('Follow invitation link')}
            </div>
          )}

          {process.env.REACT_APP_PLATFORM_TYPE !== 'ru' && (
            <div className={classes.lang}>
              <MultiselectComponent
                data={languages}
                langSelect={true}
                defaultSelected={defaultLanguage}
                setMultiselect={changeLanguage}
                className={classes.multiselect}
              />
            </div>
          )}

          <div className={classes.title}>
            {t('Login to')} <span>{t('KIT Global')}</span>
          </div>

          <div className={classes.text}>
            <span>
              {t('Don’t have an account')}?
              <Link
                className={classes.link}
                href="/register"
                query={invite ? { invite: invite } : undefined}
              >
                {t('Sign up')}
              </Link>
            </span>
          </div>
          {errors.email && (
            <div className={classes['mobile-error']}>
              {errors.email.message}
            </div>
          )}

          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <div className={classes.fields}>
              <div className={classes['input-field']}>
                <Controller
                  name="email"
                  control={control}
                  render={({
                    field,
                  }: {
                    field: ControllerRenderProps<FormInputs, 'email'>;
                  }) => (
                    <Input
                      label={t('Email')}
                      labelPosition={'left'}
                      type={'text'}
                      placeholder={t('Enter your email')}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                    />
                  )}
                />
              </div>
              <div className={classes['input-field']}>
                <Controller
                  name="password"
                  control={control}
                  render={({
                    field,
                  }: {
                    field: ControllerRenderProps<FormInputs, 'password'>;
                  }) => (
                    <Input
                      label={t('Password')}
                      labelPosition={'left'}
                      type={showPassword ? 'text' : 'password'}
                      placeholder={t('Enter password')}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e);
                        // setPassword(e);
                      }}
                    />
                  )}
                />
                <div
                  role="button"
                  tabIndex={-1}
                  onClick={() => setShowPassword(!showPassword)}
                  onKeyDown={() => {}}
                  className={classes['show-password']}
                >
                  <EyeIcon width={19} height={14} />
                </div>
                {errors.password && !isMediaTablet && (
                  <TooltipContainer
                    text={errors.password.message}
                    customClasses={'kit-ui-block'}
                    position={'top'}
                    className={classes.error}
                  >
                    <InfoIcon fill="#F5222D" width="18px" height="18px" />
                  </TooltipContainer>
                )}
                <Link
                  className={clsx(classes.link, classes.password)}
                  href="/restore"
                >
                  {t('I forgot my password')}
                </Link>
              </div>
            </div>
            {errors.password && isMediaTablet && (
              <div className={classes['mobile-error']}>
                {errors.password?.message}
              </div>
            )}
            {!isDisableCaptcha && (
              <div className={classes.captcha}>
                <Controller
                  name="captcha"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <ReCAPTCHA
                      sitekey={`${process.env.REACT_APP_GOOGLE_RECAPTHA_KEY}`}
                      onChange={(value) => field.onChange(value)}
                      hl="en"
                      size="normal"
                    />
                  )}
                />
              </div>
            )}
            <Button
              className={clsx(
                classes.button,
                classes['submit-button'],
                isDisableCaptcha && classes.disabledCaptcha
              )}
              theme="primary"
              disabled={!isValid || !isDirty}
            >
              {t('Log in')}
            </Button>
          </form>
        </div>
      </div>
    </>
  );
});
