import ColorTag, { ColorTagProps } from 'components/shared/ColorTag';
import classes from './EventCard.module.scss';
import { Button } from 'components/shared/Button';
import { PinIcon } from 'components/shared/Icons/PinIcon';
import { LinkIcon } from 'components/shared/Icons/LinkIcon';
import _ from 'lodash';
import { PurpleTimeIcon } from 'components/shared/Icons/PurpleTimeIcon';
import clamp from 'clamp-js';
import React, { useLayoutEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ClipIcon } from 'components/shared/Icons/ClipIcon';

interface EventCardProps {
  slug: string;
  imgSrc: string;
  title: string;
  description: string;
  buttonName: string;
  tags?: Omit<ColorTagProps, 'className'>[];
  eventDate: string;
  eventLocation?: string;
  eventLink?: string;
  downloadFile?: string;
}

const EventCard = (props: EventCardProps) => {
  const textRef = useRef<HTMLParagraphElement | null>(null);
  const titleRef = useRef<HTMLSpanElement | null>(null);
  const navigate = useNavigate();

  const isEventCompleted = (() => {
    const match = props.eventDate.match(/\d{2}\.\d{2}\.\d{4}/);
    return match ? new Date(match[0].split('.').reverse().join('-')) < new Date() : false;
  })();

  useLayoutEffect(() => {
    if (textRef.current) {
      clamp(textRef.current, { clamp: 2 });
    }

    if (titleRef.current) {
      clamp(titleRef.current, { clamp: 2 });
    }
  }, []);

  return (
    <div className={classes.card}>
      {props.tags && props.tags?.length > 0 && (
        <div className={classes.card__tags}>
          {props.tags?.map((tag) => (
            <ColorTag
              key={_.uniqueId()}
              color={tag.color}
              text={tag.text}
              icon={tag.icon}
            />
          ))}
        </div>
      )}
      <div className={classes.imgBox}>
        <img src={props.imgSrc} alt="" />
      </div>
      <span ref={titleRef} className={classes.card__title}>
        {props.title}
      </span>
      <p ref={textRef} className={classes.card__description}>
        {props.description}
      </p>
      <div className={classes.card__marks}>
        {props.eventDate && (
          <div className={`${classes.card__mark} ${isEventCompleted ? classes["card__mark--completed"] : ''}`}>
          <PurpleTimeIcon />
          {props.eventDate}
        </div>
        )}
        {props.eventLocation ? (
          <div className={classes.card__mark}>
            <PinIcon />
            {props.eventLocation}
          </div>
        ) : props.eventLink ? (
          <Link to={props.eventLink || '#'} className={classes.card__link}>
            <LinkIcon />
            {'Подключиться'}
          </Link>
        ) : null}
      </div>
        {props.downloadFile ? (
          <a href={props.downloadFile} download className={classes.card__downloadFile}>
            <ClipIcon />
            {'Скачать материалы'}
          </a>
        ) : (
          <div className={classes.card__downloadPlaceholder} />
        )}
      <Button
        onClick={() => navigate(`/programma-rosta/${props.slug}`)}
        theme={'primary'}
        className={classes.card__button}
      >
        {props.buttonName}
      </Button>
    </div>
  );
};

export default EventCard;
