import { PurpleTimeIcon } from 'components/shared/Icons/PurpleTimeIcon';
import classes from './AnnounceCard.module.scss';
import { PinIcon } from 'components/shared/Icons/PinIcon';
import { LinkIcon } from 'components/shared/Icons/LinkIcon';
import { Button } from 'components/shared/Button';
import { LeftArrowIcon } from 'components/shared/Icons/LeftArrowIcon';
import clsx from 'clsx';
import { Link, useNavigate } from 'react-router-dom';
import React, { forwardRef, useLayoutEffect, useRef, useState } from 'react';
import { CheckIcon } from 'components/shared/Icons/CheckIcon';
import { observer } from 'mobx-react-lite';
import clamp from 'clamp-js';
import { growthStore } from 'stores/growthProgram';
import { ClipIcon } from 'components/shared/Icons/ClipIcon';

interface AnnounceCardProps {
  title: string;
  eventDate: string;
  eventLocation?: string;
  eventLink?: string;
  nextLink?: string;
  onSubscribeEvent?: () => void;
  disabledEventButton?: boolean;
  isLoadingSubscribe?: boolean;
  downloadFile?: string; 
}

const AnnounceCard = forwardRef<HTMLDivElement, AnnounceCardProps>(
  (props, ref) => {
    const navigate = useNavigate();
    const titleRef = useRef<HTMLSpanElement | null>(null);

    const subcribe = growthStore.currentEventSubscribed;

    const isEventCompleted = (() => {
      const match = props.eventDate.match(/\d{2}\.\d{2}\.\d{4}/);
      return match ? new Date(match[0].split('.').reverse().join('-')) < new Date() : false;
    })();

    const handleSubscribe = async () => {
      if (props.onSubscribeEvent) {
        await props.onSubscribeEvent();
      }
    };

    useLayoutEffect(() => {
      if (titleRef.current) {
        clamp(titleRef.current, { clamp: 2 });
      }
    }, []);

    return (
      <div ref={ref} className={classes.card}>
        <span ref={titleRef} className={classes.card__title}>
          {props.title}
        </span>
        <div className={classes.card__marks}>
          {props.eventDate && (
            <div className={`${classes.card__mark} ${isEventCompleted ? classes["card__mark--completed"] : ''}`}>
              <PurpleTimeIcon />
              {props.eventDate}
            </div>
          )}
          {props.eventLocation ? (
            <div className={classes.card__mark}>
              <PinIcon />
              {props.eventLocation}
            </div>
          ) : isEventCompleted && props.downloadFile ? (
            <a href={props.downloadFile} download className={classes.card__downloadFile}>
              <ClipIcon />
              {'Скачать материалы'}
            </a>
          ) : !isEventCompleted && subcribe && props.eventLink && !props.nextLink? (
            <Link to={props.eventLink || '#'} 
            className={clsx(classes.card__link, classes['card__link--active'])}
            target="_blank"
            rel="noopener noreferrer"
            >
              <LinkIcon />
              {'Подключиться'}
            </Link>
          ) : !isEventCompleted && !props.nextLink ? (
            <span className={classes.card__link}>
             <LinkIcon />
              {'Ссылка на вебинар будет доступна после подтверждения участия'}
            </span>
          ) : null}
        </div>
        {!isEventCompleted && !props.nextLink &&
          (props.disabledEventButton || subcribe ? (
            <Button
              theme="primary-light"
              className={clsx(classes.card__button, classes.disabled)}
            >
              Участвую
              <CheckIcon />
            </Button>
          ) : (
            <Button
              theme={'primary'}
              className={classes.card__button}
              onClick={handleSubscribe}
              disabled={props.isLoadingSubscribe}
            >
              Буду участвовать
            </Button>
          ))}
        {props.nextLink && (
          <Button
            theme={'primary-light'}
            className={clsx(
              classes.card__button,
              classes['card__button--next']
            )}
            onClick={() => navigate(props.nextLink || '#')}
          >
            Следующее мероприятие
            <LeftArrowIcon />
          </Button>
        )}
      </div>
    );
  }
);

export default observer(AnnounceCard);
