import MainBlock from './components/MainBlock';
import classes from './EventDetail.module.scss';
import BreadCrumbs from 'components/shared/BreadCrumbs';
import {
  FIRST_EVENT_TEXT,
  SECOND_EVENT_TEXT,
  THIRD_EVENT_TEXT,
} from './tempText';
import { useParams } from 'react-router-dom';
import AnnounceCard from './components/AnnounceCard';
import { StickerColor } from 'components/shared/ColorSticker';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { useEffect, useRef, useState } from 'react';
import { useIsVisible } from 'hooks/useIsVisible';
import { Modal } from 'components/shared/Modal';
import { ModalMobile } from 'components/shared/ModalMobile';
import Map from './components/Map';
import { useGrowthProgramStore } from 'contexts/GrowthProgram';
import { PageLoader } from 'components/shared/PageLoader';
import { observer } from 'mobx-react-lite';

const mockEvents: {
  id: number;
  slug: string;
  title: string;
  description: string;
  tags: { color: StickerColor; text: string }[];
  date: string;
  img?: string;
  link?: string;
  location?: {
    name: string;
    coords: number[];
  };
  downloadFile?: string;
}[] = [
  {
    id: 1,
    slug: 'inogda-oni-vozvrashchayutsya-a-chto-esli',
    title: 'Иногда они возвращаются: а что, если?..',
    description: SECOND_EVENT_TEXT,
    tags: [
      { color: 'green' as StickerColor, text: 'Онлайн' },
      { color: 'yellow' as StickerColor, text: 'Мультикатегорийная' },
    ],
    date: 'Завершено 07.02.2025',
    link: 'https://pruffme.com/landing/u2705426/tmp1737021109',
    downloadFile: '/files/GrowthProgram/Иногда они возвращаются.pdf',
  },
  {
    id: 2,
    slug: 'ekspertnost-kak-podcherknut-svoi-supersily',
    title: 'Экспертность — как подчеркнуть свои «суперсилы»?',
    description: FIRST_EVENT_TEXT,
    tags: [
      { color: 'green' as StickerColor, text: 'Онлайн' },
      { color: 'purple' as StickerColor, text: 'Личный бренд' },
    ],
    date: '19.02.2025 в 12:00',
    link: 'https://pruffme.com/landing/u2705426/tmp1737021425',
  },
  {
    id: 3,
    slug: 'sekrety-premium-kak-prodavatsya-dorozhe',
    title: 'Секреты premium - как продаваться дороже?',
    description: THIRD_EVENT_TEXT,
    tags: [
      { color: 'green' as StickerColor, text: 'Онлайн' },
      { color: 'purple' as StickerColor, text: 'Средний бизнес' },
      {
        color: 'yellow' as StickerColor,
        text: 'Растущий бизнес',
      },
    ],
    date: '03.03.2025 в 10:30',
    link: 'https://pruffme.com/landing/u2705426/tmp1737021528',
  },
];

const EventDetailContainer = () => {
  const { slug } = useParams();
  const { width } = useWindowWidth();
  const nextEventRef = useRef<HTMLDivElement | null>(null);
  const [showMap, setShowMap] = useState(false);
  const growthStore = useGrowthProgramStore();
  const [activeEventId, setActiveEventId] = useState<number | null>(null);

  let nextEvent: any = null;
  const currentEvent = mockEvents.find((event, index) => {
    if (event?.slug === slug) {
      nextEvent = mockEvents[index + 1];
      return true;
    }
    return false;
  });

  const isNextEventVisible = useIsVisible(nextEventRef, undefined, [
    growthStore.isLoadingSubscribeStatus,
  ]);

  const callbacks = {
    onSubmitEvent: () => {
      if (currentEvent) {
        growthStore.subscribeEvent(currentEvent?.id);
      }
    },
  };

  useEffect(() => {
    if (currentEvent?.id && currentEvent.slug === slug) {
      setActiveEventId(currentEvent.id);
    }
  }, [currentEvent?.id, slug]);

  useEffect(() => {
    if (activeEventId !== null) {
      growthStore.getSubscribeStatus(activeEventId);
    }
  }, [activeEventId]);

  return (
    <div key={activeEventId} className={classes.wrapper}>
      <BreadCrumbs
        link="/programma-rosta/"
        linkName="Программа роста"
        className={classes.breadcrumbs}
        items={[currentEvent?.title || '#']}
      />

      {growthStore.isLoadingSubscribeStatus ? (
        <PageLoader />
      ) : (
        <div className={classes.content}>
          {currentEvent && (
            <>
              <MainBlock
                title={currentEvent.title}
                tags={currentEvent.tags}
                eventDate={currentEvent.date}
                eventLocation={currentEvent.location?.name}
                eventLink={currentEvent.link}
                buttonName="Буду участвовать"
                description={currentEvent.description}
                showFloatButton={
                  !growthStore.isLoadingSubscribeStatus && !isNextEventVisible
                }
                onShowMap={() => setShowMap(true)}
                onSubscribeEvent={callbacks.onSubmitEvent}
                disabledEventButton={growthStore.currentEventSubscribed}
                isLoadingSubscribe={growthStore.isLoadingSubscribe}
                downloadFile={currentEvent.downloadFile} 
              />
              <div className={classes.announceBlocks}>
                {width > 600 && (
                  <AnnounceCard
                    title={currentEvent.title}
                    eventDate={currentEvent.date}
                    eventLocation={currentEvent.location?.name}
                    eventLink={currentEvent.link}
                    onSubscribeEvent={callbacks.onSubmitEvent}
                    disabledEventButton={growthStore.currentEventSubscribed}
                    isLoadingSubscribe={growthStore.isLoadingSubscribe}
                    downloadFile={currentEvent.downloadFile}
                  />
                )}
                {nextEvent && (
                  <AnnounceCard
                    ref={nextEventRef}
                    title={nextEvent.title}
                    eventDate={nextEvent.date}
                    eventLocation={nextEvent.location?.name}
                    eventLink={nextEvent.link}
                    nextLink={`/programma-rosta/${nextEvent.slug}`}
                  />
                )}
              </div>
            </>
          )}
        </div>
      )}

      {currentEvent?.location && width > 640 && (
        <Modal
          isOpen={showMap}
          onClose={() => setShowMap(false)}
          subModal={true}
          title=""
          className={classes.modalFilter}
          classNameForContent={classes.modalMobileFilters}
          withMediaMobile={false}
        >
          <Map
            addressName={currentEvent?.location?.name}
            coordinates={currentEvent.location.coords}
            zoomPosition={{ right: 10, bottom: 30 }}
          />
        </Modal>
      )}

      {currentEvent?.location && width < 640 && (
        <ModalMobile
          title=""
          isOpen={showMap}
          onClose={() => setShowMap(false)}
          isBasicHeader={false}
        >
          <Map
            addressName={currentEvent?.location?.name}
            coordinates={currentEvent.location.coords}
            zoomPosition={{ left: 10, bottom: 40 }}
          />
        </ModalMobile>
      )}
    </div>
  );
};

export default observer(EventDetailContainer);
