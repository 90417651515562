import ColorTag, { ColorTagProps } from 'components/shared/ColorTag';
import classes from './MainBlock.module.scss';
import { formatHtmlText } from 'tools/formatHtmlText';
import DOMPurify from 'dompurify';
import _ from 'lodash';
import { PurpleTimeIcon } from 'components/shared/Icons/PurpleTimeIcon';
import { PinIcon } from 'components/shared/Icons/PinIcon';
import IconWithDescription from 'components/shared/IconWithDescription';
import { LinkIcon } from 'components/shared/Icons/LinkIcon';
import { Button } from 'components/shared/Button';
import { useIsVisible } from 'hooks/useIsVisible';
import React, { useRef } from 'react';
import { useWindowWidth } from 'hooks/useWindowWidth';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { CheckIcon } from 'components/shared/Icons/CheckIcon';
import { observer } from 'mobx-react-lite';
import { AttentionIcon2 } from 'components/shared/Icons/Attention2';
import { useTranslation } from 'react-i18next';
import { RUSSIAN_LOCALE } from 'utils';
import { growthStore } from 'stores/growthProgram';
import { ClipIcon } from 'components/shared/Icons/ClipIcon';

interface MainBlockProps {
  imgSrc?: string;
  tags?: Omit<ColorTagProps, 'className'>[];
  title: string;
  eventDate: string;
  eventLocation?: string;
  eventLink?: string;
  buttonName: string;
  description: string;
  showFloatButton?: boolean;
  onShowMap?: () => void;
  onSubscribeEvent?: () => void;
  disabledEventButton?: boolean;
  isLoadingSubscribe?: boolean;
  downloadFile?: string;
}

const MainBlock = (props: MainBlockProps) => {
  const mainButtonRef = useRef<HTMLButtonElement | null>(null);
  const isVisibleMainButton = useIsVisible(mainButtonRef);
  const { width } = useWindowWidth();
  const { t } = useTranslation();

  const isEventCompleted = (() => {
    const match = props.eventDate.match(/\d{2}\.\d{2}\.\d{4}/);
    return match ? new Date(match[0].split('.').reverse().join('-')) < new Date() : false;
  })();

  const subscribe = growthStore.currentEventSubscribed;
  
      const handleSubscribe = async () => {
        if (props.onSubscribeEvent) {
          await props.onSubscribeEvent();
        }
      };


const attentionText = {
    title: 'Информация для участия',
    description: 'Чтобы участвовать в мероприятии, нажмите на кнопку «Буду участвовать». После этого в течение 2х рабочих дней вам на почту, которая была указана при регистрации на платформе, будет отправлена вся дополнительная информация по мероприятию.',
    descriptionTwo: 'Если вы хотите проверить, какая почта указана в вашем профиле, нажмите в верхнем правом углу на иконку профиля. Если у вас есть вопросы по мероприятию или другие уточнения, напишите',
    link: 'нам в чат',
  };

const attentionCompleted = {
    title: 'Материалы для скачивания',
    description: 'Материалы с прошедшего мероприятия доступны для скачивания. Если вы были участником, эти материалы станут прекрасным помощником для сохранения в памяти основных идей и ключевых моментов встречи. А если вам не удалось принять участие, скачав их, вы сможете ознакомиться с представленными информацией и получить все необходимые знания.',
    link: 'Скачать материалы',
  };

  const openChat = () => {
    const chatIframe = document.getElementById('fsw-iframe') as HTMLElement;
    if (chatIframe) {
      chatIframe.style.display = 'block'; 
    }
  };

  return (
    <div className={classes.mainBlock}>
      {props.imgSrc && (
        <div className={classes.mainBlock__imgBox}>
          <img src={props.imgSrc} alt="" />
        </div>
      )}
      <div className={classes.mainBlock__header}>
        <div className={classes['mainBlock__header-tags']}>
          {props.tags?.map((tag) => (
            <ColorTag
              key={_.uniqueId()}
              color={tag.color}
              text={tag.text}
              icon={tag.icon}
            />
          ))}
        </div>
        <span className={classes['mainBlock__header-title']}>
          {props.title}
        </span>
      </div>
      <div className={classes.mainBlock__info}>
        <IconWithDescription
          icon={<PurpleTimeIcon />}
          title={'Когда'}
          description={props.eventDate}
          descriptionClassName={clsx({ [classes['mainBlock__info-completed']]: isEventCompleted })}
        />
        {props.eventLocation && (
          <div>
            <IconWithDescription
              icon={<PinIcon />}
              title="Где"
              description={props.eventLocation}
            />
            <Button
              theme="text"
              className={classes['mainBlock__info-textButton']}
              onClick={props.onShowMap}
            >
              Карта и схема проезда
            </Button>
          </div>
        )}
        {!isEventCompleted && width < 600 && (props.eventLocation ? (
          <div className={classes.mainBlock__mark}>
           <PinIcon />
           {props.eventLocation}
          </div>
        ) : subscribe && props.eventLink ? (
          <Link to={props.eventLink || '#'} className={clsx(classes.mainBlock__link, classes['mainBlock__link--active'])}>
           <LinkIcon />
           {'Подключиться'}
          </Link>
        ) : (
          <span className={classes.mainBlock__link}>
           <LinkIcon />
           {'Ссылка на вебинар будет доступна после подтверждения участия'}
          </span>
        ))}
        {!isEventCompleted && (props.disabledEventButton ? (
          <Button
            theme="primary-light"
            className={clsx(
              classes['mainBlock__info-mainButton'],
              classes.disabled
            )}
          >
            Участвую
            <CheckIcon />
          </Button>
        ) : (
          <Button
            ref={mainButtonRef}
            theme="primary"
            className={classes['mainBlock__info-mainButton']}
            onClick={props.onSubscribeEvent}
            disabled={props.isLoadingSubscribe}
          >
            Буду участвовать
          </Button>
        )
        )}
      </div>

      {RUSSIAN_LOCALE && (
      <div className={classes.attention}>
                  <AttentionIcon2 style={{ flexShrink: 0 }} />
      
                  <ul className={classes.attention__list} translate="no">
                    <li className={classes.attention__title}>
                    {isEventCompleted ? attentionCompleted.title : attentionText.title}
                    </li>
                    <li className={classes.attention__description}>
                    {isEventCompleted ? attentionCompleted.description : attentionText.description}
                      <br />
                    {!isEventCompleted && <br />}
                    </li>
                    {isEventCompleted && props.downloadFile ? (
                    <li className={classes.attention__description}>
                      <a
                        href={props.downloadFile}
                        download
                        className={classes.attention__file}
                      >
                        <ClipIcon />
                        {'Скачать материал'}
                      </a>
                    </li>
                    ) : (
                    <li className={classes.attention__description}>
                      {attentionText.descriptionTwo}{' '}
                      <a
                        href="#"
                        onClick={(e) => {
                        e.preventDefault(); 
                        openChat(); 
                         }}
                         className={classes.attention__link}
                       >
                        {attentionText.link}
                      </a>
                      {'.'}
                    </li>
                     )}
                  </ul>
                </div>
                 )}

      <div
        dangerouslySetInnerHTML={formatHtmlText(
          DOMPurify.sanitize(props.description)
        )}
        className={classes.mainBlock__description}
      />

      {width < 600 &&
        !isVisibleMainButton &&
        !props.disabledEventButton &&
        props.showFloatButton && (
          <Button
            theme="primary"
            className={clsx(
              classes['mainBlock__info-mainButton'],
              classes.float
            )}
            onClick={props.onSubscribeEvent}
            disabled={props.isLoadingSubscribe}
          >
            Буду участвовать
          </Button>
        )}
    </div>
  );
};

export default observer(MainBlock);
